.Header{
    grid-area: header;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #000  ;
}

.Header img{
    width: 990px;
    height: 110px;
}

.Header .icones{
    display: flex;
    width: 900px;
    margin-top: 10px;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.Header .icones .icon {
    min-width: 300px;
    border:1px solid;
    border-color: var(--orange);
    border-radius: 10px;
    margin-bottom: 20px;    
    /*color: var(--orange);*/
    padding: 20px;
    margin: 20px;
    display: flex;
    align-items: center;
    transition: background-color ease-in .1s;
}

.Header .icones .icon.link {
    cursor: pointer;
}

.Header .icones .icon:hover {
    background-color: var(--orange);
    transition: background-color ease-in .1s;
    color: #000;
}

.Header .icones .icon i {
    margin-right: 10px;
    font-size: 48px;
    color: var(--orange);
}
.Header .icones .icon:hover i {
    color: #000;
}