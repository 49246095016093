.Mapa{
    grid-area: map;
    background-color: #000;
    display: flex;
    flex-direction: column;
}

.Mapa .title {
    height: 30px;
}

.Mapa .title i{
    margin-right: 5px;
}

.Mapa iframe{
    flex: 1;
}