.App {
  display: grid;
  height: 100vh;

  grid-template-areas: 
    'header'
    'map'
    'footer';

  grid-template-rows: auto 340px 30px;
}

.title {
  color: var(--orange);
  font-size: 24px;
  font-weight: 300;
  padding: 10px;
  display: flex;
  align-items: center;

}