.Footer{
    grid-area: footer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}

.Footer i {
    margin-right: 5px;
}